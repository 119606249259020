<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>عرض</label>
            <v-select

              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label />
          </b-col>
          <b-col
            cols="6"
            class="d-flex align-items-end justify-content-end mb-1 mb-md-0"
          >
            <b-button

              variant="primary"
              class="mr-1"
              @click="getData"
            >
              حفظ
            </b-button>
          </b-col>

          <!-- Search -->

        </b-row>

      </div>

      <b-table
        ref="refCourseListTable"
        class="position-relative"

        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="categoriesList"
        striped
        show-empty
        empty-text="  يتم جلب البيانات"
      >

        <!-- Column: Status -->
        <template #cell(visible)="data">
          <b-form-group class="mb-0">
            {{ data.item.visible }}
            <b-form-radio-group
              v-model="data.item.visible"

              class="demo-inline-spacing"
            >
              <b-form-radio
                v-model="data.item.visible"
                :value="yes"
              >
                نعم
              </b-form-radio>
              <b-form-radio
                v-model="data.item.visible"
                :value="no"
              >
                لا
              </b-form-radio>

            </b-form-radio-group>
          </b-form-group>
        </template>

        <!-- Column: Actions -->
        <template #cell(instructor.image)="data">
          <div class="border rounded p-2">

            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <b-media-aside>
                <b-img
                  ref="refPreviewE2"

                  :src="`https://ar.lpcentre.com/${data.item.instructor.image}`"
                  height="110"
                  width="170"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
              </b-media-aside>
              <b-media-body />
            </b-media>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <a
            v-if="$can('delete', 'feature_course')"
            style="margin: 0px 12px"
            role="button"
            @click="DeleteItem(data.item.id)"
          >
            <feather-icon icon="TrashIcon" />
          </a>

        </template>
        <!-- <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

<b-dropdown-item >
              <feather-icon icon="LinkIcon" />
              <span class="align-middle ml-50">Link</span>
            </b-dropdown-item>
         <b-dropdown-item-button @click="DeleteItem(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item-button>

          </b-dropdown>
        </template> -->

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              عرض {{ dataMeta.from }} الى {{ dataMeta.to }} من {{ dataMeta.of }} المدخلات
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BMedia,BPagination,
 BFormRadio, BFormRadioGroup, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, watch, computed, reactive,
} from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'

// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'

export default {
  components: {

    // useCousresList,
    BFormRadio,
    BFormRadioGroup,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BPagination,
   
    vSelect,
  },
  setup() {
    const categoriesList = ref([])
    const yes = ref(1)
    const no = ref(0)
    const perPageOptions = [25, 50, 100, 200]
    const perPage = ref(10)
    const totalCategories = ref(0)
    const currentPage = ref(1)
    const refCourseListTable = ref('')
    const visiable = ref('')
    const meta = reactive({})
    const getDataFeature = () => {
      store.dispatch('homepage/AllFeatureCourse', {

        per_page: perPage.value,
        page: currentPage.value,

      })
        .then(response => {
          console.log('AllFeatureCourse', response.data)
          categoriesList.value = response.data.data
          totalCategories.value = response.data.meta.total
          console.log('totalCategories', totalCategories)
        })
    }
    const getData = () => {
      console.log('data', categoriesList.value)
      store
        .dispatch('homepage/addAllUpComingCourse', categoriesList.value)
        .then(response => {
          Vue.swal({
            title: ' Changes saved successfuly ',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          Vue.swal({
            title: '',
            text: `${error.response.data.message}`,
            icon: 'error',
            confirmButtonText: 'حسنا',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    }

    const tableColumns = [

      { key: 'id', label: 'الرقم' },
      { label: 'الدورة', key: 'course.name' },
      { label: 'المدينة', key: 'schedules.city' },
      { label: 'الموقع', key: 'location' },
      { label: 'التاريخ', key: 'schedules.date' },
      {
        label: 'المدرس',
        key: 'instructor.image',

      },

      { key: 'actions', label: 'الخيارات' },

    ]
    watch([currentPage, perPage], () => {
      getDataFeature()
    })
    const dataMeta = computed(() => {
      const localItemsCount = refCourseListTable.value ? refCourseListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCategories.value,
      }
    })
  
    getDataFeature()
    const DeleteItem = id => {
      Vue.swal({
        icon: 'warning',
        title: 'هل انت متاكد من الحذف ?',
        confirmButtonText: 'نعم',
        cancelButtonText: 'الغاء',
        customClass: {
          confirmButton: 'btn btn-primary',

        },
      }).then(result => {
        if (result.value) {
          store.dispatch('homepage/DeleteFeatureCourse', id)
            .then(response => {
              getDataFeature()
              Vue.swal({
                title: 'تم الحذف  ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }

    return {
      tableColumns,
      yes,
      no,
      categoriesList,
      DeleteItem,
      meta,
      visiable,
      getDataFeature,
      perPageOptions,
      totalCategories,
      getData,
      currentPage,
      perPage,
      dataMeta,
      refCourseListTable,

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
